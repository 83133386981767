<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.document">
			<template #title>Documents</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'DocumentsNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>

			<v-data-table :headers="headers" :items="documents" item-key="id" :search="search" @click:row="editItem" :item-class="() => 'hover-pointer'">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {call, sync} from "vuex-pathify";

export default {
	name: "DocumentsIndex",
	computed:{
		documents: sync('documents/documents')
	},
	data: () => ({
		search: '',
		headers:[
			{
				text: 'Filename',
				align: 'start',
				sortable: true,
				value: 'filename',
			},
			{
				text: 'Slug',
				align: 'start',
				sortable: true,
				value: 'slug',
			},
		]
	}),
	beforeMount(){
		this.init()
	},
	methods:{
		init(){
			this.getDocuments(null)
		},
		editItem(data){
			this.$router.push({name:'DocumentsEdit', params: {id: data.id}})
		},
		getDocuments: call('documents/getDocuments')
	}
}
</script>